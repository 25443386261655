import Cookies from 'js-cookie';
import { lazy, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import Loadable from './components/Loadable';
import Loading from './components/MatxLoading';
// import Layout from './components/layout';
import MatxLayout from './components/MatxLayout/MatxLayout';
import OTPVerification from './components/sessions/OTPVerification';
import axios from './utils/axios';
import { handleDomainRedirect } from './utils/helpers';
import { dispatch } from './reducers/configureStore';
import { setUserData } from './reducers/slices/users';
// import InvitePage from './views/users/UserInvite';
// import JwtRegister from './components/sessions/JwtRegister';

const InvitationManagement = Loadable(lazy(() => import('./views/invitation/invitation')));
const InvitePage = Loadable(lazy(() => import('./views/users/UserInvite')));
const UserManagement = Loadable(lazy(() => import('./views/users/UserManagement')));
const AuthGuard = Loadable(lazy(() => import('./components/auth/AuthGuard')));
const JwtLogin = Loadable(lazy(() => import('./components/sessions/JwtLogin')));
const ImpersonateLogin = Loadable(lazy(() => import('./components/sessions/ImpersonateLogin')));
const JwtRegister = Loadable(lazy(() => import('./components/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('./components/sessions/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('./components/sessions/ResetPassword')));
const UserApp = Loadable(lazy(() => import('./views/userApps/UserApps')));
const WelcomPage = Loadable(lazy(() => import('./components/sessions/WelcomPage')));

const REG_EX_FOR_REDIRECT_URL = /redirect_url=(.*)/;

const Router = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationRef = useRef(location);
  const navigateRef = useRef(navigate);

  const [showLoading, setShowLoading] = useState(true);
  let redirectUrl = null;
  if (['/admin', '/admin/'].includes(location.pathname)) {
    localStorage.removeItem('redirectUrl');
  } else {
    const matchedUrl = window.location.href.match(REG_EX_FOR_REDIRECT_URL);
    redirectUrl = (matchedUrl ? matchedUrl[1] : null) || localStorage.getItem('redirectUrl');
  }

  useEffect(() => {
    (async () => {
      try {
        const navigate = navigateRef.current;
        const path = locationRef.current.pathname;
        const isAuthRoute = [
          '/signin',
          '/signup',
          '/forgot-password',
          '/reset-password',
          '/otp-verification',
          '/impersonate_login'
        ].includes(path);
        const authToken = localStorage.getItem('accessToken');
        if (authToken) {
          // if (Cookies.get('access_token')) {
          const responseData = await axios.get('/api/user_details');
          dispatch(setUserData(responseData.data));
          if (responseData?.status === true) {
            if (redirectUrl) {
              handleDomainRedirect(Cookies.get('access_token'));
              return;
            }

            if (isAuthRoute) {
              navigate('/admin/users');
            }
          }
        } else if (!isAuthRoute) {
          navigate('/signin');
        }
      } catch (e) {
        console.log(e);
      } finally {
        setShowLoading(false);
      }
    })();
  }, [redirectUrl]);

  useEffect(() => {
    if (redirectUrl) localStorage.setItem('redirectUrl', redirectUrl);
  }, [redirectUrl]);

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <MatxLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin/users" />, index: true },
        { path: '/admin/users', element: <UserManagement /> },
        { path: '/admin/invitations/create', element: <InvitePage /> },
        { path: '/admin/invitations/:id', element: <InvitePage /> },
        { path: '/admin/invitations', element: <InvitationManagement /> },
        { path: '/admin/apps', element: <UserApp /> }
      ]
    },
    { path: '/signup', element: <JwtRegister /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset-password/:user_id/:token', element: <ResetPassword /> },
    { path: '/otp-verification', element: <OTPVerification /> },
    { path: '/impersonate_login', element: <ImpersonateLogin /> },
    { path: '/signin', element: <JwtLogin /> },
    { path: '/welcome', element: <WelcomPage /> },

    { path: '*', element: <Navigate to="/" /> }
  ]);
  return showLoading ? <Loading /> : routes;
};

export default Router;
