import { combineReducers, configureStore } from '@reduxjs/toolkit';
import User from './slices/users';
import AlertsSlice from './slices/AlertsSlice';
import HelperSlice from './slices/HelperSlice';
import LayoutSlice from './slices/LayoutSlice';
import MasterSlice from './slices/MasterSlice';
import FilterSlice from './slices/FilterSlice';

const store = configureStore({
  reducer: combineReducers({ User, AlertsSlice, HelperSlice, Layout: LayoutSlice, MasterSlice, FilterSlice })
});

export const { dispatch, getState } = store;

export default store;
