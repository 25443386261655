import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitationUserTypesFilter: null,
  userTableUserTypesFilter: null,
  isInvitedUserFilter: null
};

const FilterSlice = createSlice({
  name: 'FilterSlice',
  initialState,
  reducers: {
    setInvitationUserTypesFilter: (state, action) => {
      state.invitationUserTypesFilter = action.payload;
    },
    setIsInvitedUserFilter: (state, action) => {
      state.isInvitedUserFilter = action.payload;
    },
    setUserTableUserTypesFilter: (state, action) => {
      state.userTableUserTypesFilter = action.payload;
    }
  }
});

export const { setInvitationUserTypesFilter, setIsInvitedUserFilter, setUserTableUserTypesFilter } = FilterSlice.actions;

export default FilterSlice.reducer;
