import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MatxTheme } from './components';
import NotificationAlerts from './components/alerts/NotificationAlerts';
import SnackNotificationProvider from './components/SnackNotificationProvider/SnackNotificationProvider';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './reducers/configureStore';
import Router from './routes';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <SettingsProvider>
          <MatxTheme>
            <Provider store={store}>
              <SnackNotificationProvider>
                <NotificationAlerts />
                <Router />
              </SnackNotificationProvider>
            </Provider>
          </MatxTheme>
        </SettingsProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
