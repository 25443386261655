// import parsePhoneNumber from 'libphonenumber-js/max';
import axios from './axios';
import { appConfig } from '../config';
import Cookies from 'js-cookie';

const logoutUserHandler = async () => {
  if (!Cookies.get('access_token')) {
    window.location.href = '/';
  }
  await axios.get(`${appConfig.API_URL}/api/logout`);
  localStorage.clear();
  window.location.href = '/signin';
};

const setUserInLocalStorage = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
};

const handleDomainRedirect = (access_token = null) => {
  const redirectUrl = localStorage.getItem('redirectUrl');
  if (!redirectUrl) return;
  localStorage.clear();

  if (access_token) {
    try {
      const urlWithToken = new URL(redirectUrl);
      const newQueryParams = new URLSearchParams(urlWithToken.search);
      newQueryParams.set('access_token', access_token);

      urlWithToken.search = newQueryParams.toString();
      window.location.href = urlWithToken.href;
    } catch (e) {
      console.log(e);
    }
  } else {
    window.location.href = redirectUrl;
  }
};

// const checkMobilePhoneValidity = (mobilePhone) => {
//   const phoneNumber = parsePhoneNumber(mobilePhone);
//   if (phoneNumber?.getType() === 'MOBILE' || phoneNumber?.getType() === 'FIXED_LINE_OR_MOBILE') {
//     return true;
//   } else {
//     return false;
//   }
// };

const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

export const UserManagmentTableColDef = [
  {
    headerName: 'ID',
    field: 'id',
    minWidth: 80,
    maxWidth: 80
  },
  {
    headerName: 'Guid',
    field: 'guid',
    colId: 'guid',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'User Name',
    field: 'user_name',
    colId: 'username',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },

  {
    headerName: 'Entity Url Email',
    field: 'entity_url_email',
    filter: 'agTextColumnFilter',
    colId: 'entity_url_Email',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'Type',
    field: 'type',
    colId: 'type',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponent: 'UserTypeCustomDropdownFilter',
    cellRenderer: 'UserTypeRenderer',
    suppressFloatingFilterButton: true
  },
  {
    headerName: ' Account Status Choice',
    colId: 'account_status_choice',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    field: ' account_status_choice',
    suppressFloatingFilterButton: true
  },

  {
    headerName: 'Created Using Invitation',
    floatingFilterComponent: 'InvitedCustomFilter',
    field: 'created_using_invitation',
    colId: 'created_using_invitation',
    cellRenderer: 'StatusRenderer',
    floatingFilter: true,
    suppressFloatingFilterButton: true,
    minWidth: 220
  },
  {
    headerName: 'Actions',
    cellRenderer: 'UserManagementActionRenderer',
    minWidth: 150,
    sortable: false
  }
];
const paginationItems = [
  {
    pageSize: 50,
    valueToShow: 50
  },
  {
    pageSize: 100,
    valueToShow: 100
  },
  {
    pageSize: 200,
    valueToShow: 200
  }
];
const userTypes = [
  // { id: 1, label: 'Superadmin' }
  // // { id: 2, label: 'Admin' },
  { id: 3, label: 'User' }
];

export const InvitationUserManagementTableColDef = [
  {
    headerName: 'ID',
    field: 'id',
    minWidth: 80,
    maxWidth: 80
  },
  {
    headerName: 'User Name',
    field: 'user_name',
    colId: 'user_name',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'Type',
    field: 'type',
    colId: 'type',
    filter: 'agTextColumnFilter',
    cellRenderer: 'UserTypeRenderer',
    floatingFilterComponent: 'UserTypeCustomDropdownFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },

  {
    headerName: 'User Email',
    field: 'user_email',
    filter: 'agTextColumnFilter',
    colId: 'user_email',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'Invitation Accepted At',
    field: 'invitation_accepted_at',
    colId: 'invitation_accepted_at',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'Invitation Expired At',
    field: 'invitation_expired_at',
    colId: 'invitation_expired_at',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },

  {
    headerName: 'Actions',
    cellRenderer: 'ActionRenderer',
    minWidth: 150,
    sortable: false
  }
];

export const UserAppsManagementTableColDef = [
  {
    headerName: 'ID',
    field: 'id',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    suppressRowClickSelection: true,
    minWidth: 80
  },
  {
    headerName: 'User Name',
    field: 'userName',
    colId: 'userName',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'User Apps',
    field: 'appName',
    colId: 'appName',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressFloatingFilterButton: true
  },
  {
    headerName: 'User Json',
    field: 'userJson',
    colId: 'userJson'
  },

  {
    headerName: 'Actions',
    cellRenderer: 'UserAppsActionRenderer',
    minWidth: 150,
    sortable: false
  }
];

const createCustomFilter = (columnName, value, type = 'exact') => ({
  column_name: columnName,
  type,
  value
});

export {
  convertHexToRGB,
  logoutUserHandler,
  setUserInLocalStorage,
  errorMessageHandler,
  handleDomainRedirect,
  paginationItems,
  userTypes,
  createCustomFilter
};
