import axios from 'axios';
import { get } from 'lodash';
import { appConfig } from '../config';
import { logoutUserHandler } from '../utils/helpers';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = appConfig.API_URL;
axiosInstance.defaults.withCredentials = !appConfig.REACT_APP_LOCAL_DEV;

// Request Interceptor. All Request pass from here
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    if (!axiosInstance.defaults.withCredentials) {
      if (appConfig.REACT_APP_LOCAL_DEV === true) {
        const authToken = localStorage.getItem('accessToken');
        if (authToken) {
          axiosConfig.headers['Authorization'] = `${authToken}`;
        }
      }
    }

    if (axiosConfig.rawRequest) {
      // header for file_upload, video/audio will handle here i.e raw requests
    } else {
      axiosConfig.headers['Content-Type'] = 'application/json';
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

/*
  Response Interceptor
  Responsibilities:
  1- If api sends 401 token then send user to login page
*/
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  function (error) {
    if (get(error, 'response.status', '') === 401) {
      // un-authenticated user! clear storage and take user to Login page.
      logoutUserHandler();
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
