import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reRenderer: false
};

const MasterSlice = createSlice({
  name: 'MasterSlice',
  initialState,
  reducers: {
    setActiveRenderer: (state) => {
      state.reRenderer = !state.reRenderer;
    }
  }
});

export const { setActiveRenderer } = MasterSlice.actions;

export default MasterSlice.reducer;
